var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "asyent-window",
        {
          attrs: {
            "header-actions": _vm.headerActions,
            "page-path": _vm.pagePath,
            title: _vm.title,
            "toolbar-actions": _vm.toolbarActions,
            widget: _vm.widget,
            "title-icon": "mdi-office-building"
          },
          on: {
            "toolbar-action": function($event) {
              return _vm.toolbarMethod($event)
            }
          }
        },
        [
          _c("asyent-search", {
            attrs: {
              "filter-dialog": _vm.filterDialog,
              filters: _vm.filters,
              headers: _vm.headers,
              "reload-counter": _vm.reloadCounter,
              "row-actions": _vm.rowActions,
              "row-method": _vm.rowMethod,
              "search-method": _vm.search
            },
            on: {
              "filter-dialog": function($event) {
                _vm.filterDialog = $event
              }
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "bottom-space" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }